* {
  box-sizing: border-box;
  margin: 0;
}

a {
  text-decoration: none;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: #f6f6f7;
}
